import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'rtpca-success-alert',
    templateUrl: './success-alert.component.html',
    imports: [TranslateModule]
})
export class SuccessAlertComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SuccessAlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) {}
}
