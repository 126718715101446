import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'rtpca-error-alert',
    templateUrl: './error-alert.component.html',
    styles: [``],
    imports: [NgForOf, NgIf, TranslateModule]
})
export class ErrorAlertComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<ErrorAlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) {}
  get errors(): string[] {
    return this.data?.split(';');
  }
}
